import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {toggleAction} from "../../redux/store";
import {movingPartsListSelector} from "../../redux/toggle-slice/toggleSlice";
import callPeerRemoteModule from "../../../core/utils/callPeerRemoteModule";
import MovingPart from "./MovingPart";


const MovingParts = () => {
  const dispatch = useDispatch();

  const movingPartsList = useSelector(movingPartsListSelector);


  

  return (
    <div className={"moving-parts"}>
      <ul>
        {movingPartsList && movingPartsList.map((item) => (
          <MovingPart key={item.title} movingPart={item}/>
        ))}
      </ul>
    </div>
  )
}

export default MovingParts;
