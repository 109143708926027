/**
 * toggle ui redux
 * */

import {createSelector, createSlice} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";
import { DUMMY_PARTS } from "../../../core/dummy/movingPartsList";

const initialState = {
    background: "DESERT",            // current background
    screenShotBackground: "",
    backgroundView:false,           // background ui view on off
    hotspot: false,
    extLight: false,
    intLight: false,
    viewController: false,          // not use
    camera: { currentCameraType: "EXT", currentCameraCode: "" },                  // camera ui view on off ( false = ext, true = int)
    attract:false,                   // attract ui view on off
    movingPartsList: DUMMY_PARTS,
    cardFlipToggle: [],
    preselectionDetailReady:false
}

export const toggleSlice = createSlice({
    name : "toggle",
    initialState : initialState,
    reducers : {
        clearToggle(state,action) {
          const stateKeys = Object.keys(initialState);
          for(let key of stateKeys){
              state[key] = initialState[key];
          }
        },
        setToggle(state,action){
            state[action.payload.key] = action.payload.value
        },
        resetMovingPartsState(state, action) {
            state.movingPartsList = initialState.movingPartsList
        },
        setCardFlipToggle(state, action) {
            state.cardFlipToggle = [...action.payload];
        },
        setPreselectionDetailReady(state, action) {
            state.preselectionDetailReady = action.payload;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => initialState);
    }
})



export const cameraSelector = createSelector(
  (state) => state.toggle.camera || initialState.camera,
  (camera) => {
        return camera
  }
)

export const movingPartsListSelector = createSelector(
  (state) => state.toggle.movingPartsList || initialState.movingPartsList,
  (movingPartsList) => {
      return movingPartsList;
  }
)
export const cardFlipToggleSelector = createSelector(
  (state) => state.toggle.cardFlipToggle || initialState.cardFlipToggle,
  (cardFlipToggle) => {
      return cardFlipToggle;
  }
)

