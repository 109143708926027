import callPeerRemoteModule from "../../../core/utils/callPeerRemoteModule";
import React, {useEffect} from "react";
import {shallowEqual, useSelector} from "react-redux";


const MovingPart = ({movingPart}) => {
  const [activeButton, setActiveButton] = React.useState(false);
  const {connectMode, currentNavigation} = useSelector(state => ({
    connectMode: state.theme.connectMode,
    currentNavigation: state.optionWindow.currentNavigation,
  }), shallowEqual);

  useEffect(() => {
    if (currentNavigation !== 'exterior') {
      setActiveButton(false);
    }
  }, [currentNavigation]);


  return (
    <li key={movingPart.title}>
      <button type={"button"} className={activeButton ? "active" : ""}
              onClick={async () => {
                console.log(movingPart.code)
                const peer = await callPeerRemoteModule(connectMode);
                setActiveButton(!activeButton);
                // dispatch(toggleAction.movingPartsControl(item.code));
                // peer && peer.conn.sendData("anim:event",item.code);
                if (peer) {
                  for (let code of movingPart.code) {
                    peer && peer.conn.sendData("anim:event", code);
                  }
                }
              }}>
        {movingPart.title}
      </button>
    </li>
  )
}

export default MovingPart;